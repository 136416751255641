import { tryToGetAccessJWT } from "../util/auth/for-interceptor-lib";

function AuthInterceptor($rootScope, $q, $cookies, $injector, Util) {
  'ngInject';

  return {
    // Add authorization token to headers
    request: async (config) => {
      config.headers = config.headers || {};

      const anAccessJWT = await tryToGetAccessJWT().catch(() => null)

      if (anAccessJWT && Util.isSameOrigin(config.url)) {
        config.headers.Authorization = 'Bearer ' + anAccessJWT.encoded;
        config.headers.OriginReferer =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname;

        return config
      }

      throw new Error('No JWT');
    },

    // Intercept 401s and redirect you to login
    responseError(response) {
      return $q.reject(response);
    }
  };
}


function AuthConfig($httpProvider, $provide) {
  'ngInject';

  exceptionHandler.$inject = ['$delegate', '$window'];
  function exceptionHandler ($delegate, $window){
    return function (exception, cause) {
      window.__errors = window.__errors || [];
      window.__errors.push({ exception, cause });
      $delegate(exception, cause);
    }
  }

  $provide.decorator('$exceptionHandler', exceptionHandler);
  $httpProvider.interceptors.push(AuthInterceptor);

  $httpProvider.defaults.useXDomain = true;
  delete $httpProvider.defaults.headers.common['X-Requested-With'];
}

export default AuthConfig;
